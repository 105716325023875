import React from 'react'

export function wrapPageElement({ element, props }) {
  if (element.type.StoresWrapper) {
    const Wrapper = element.type.StoresWrapper
    return (
      <Wrapper isStoreIndex={element.type.isStoreIndex} {...props}>
        {element}
      </Wrapper>
    )
  } else {
    const Wrapper = React.Fragment
    return <Wrapper {...props}>{element}</Wrapper>
  }
}


export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
   const currentPosition = getSavedScrollPosition(location)

  if (location.pathname.includes('/stores')) {
    document.querySelector('#focus')?.scroll(window.innerWidth,0)
    document.querySelector('#contents')?.scroll(window.innerWidth ,0)
  } 
  return true
}